import { Navigate, useRoutes } from 'react-router-dom';
import Login from './pages/Login';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Login />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
