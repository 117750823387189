import { ReactNode } from 'react';
import { CssBaseline } from '@material-ui/core';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@material-ui/core/styles';
import { shape } from './shape';
import { palette } from './palette';
import { typography } from './typography';
import { GlobalStyles } from './globalStyles';
import componentsOverride from './overrides';
import { customShadows, shadows } from './shadows';

interface ThemeConfigProp {
  children: ReactNode;
}

export function ThemeConfig({ children }: ThemeConfigProp) {
  const theme = createTheme({
    palette,
    shape,
    typography,
    // @ts-ignore
    shadows,
    customShadows
  });
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
