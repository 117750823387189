import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  background: '#FDF8F1',
  width: '100%',
  height: '100%'
}));

const SignUpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  padding: theme.spacing(0)
}));

const LoginPageContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  flexDirection: 'row',
  padding: theme.spacing(0)
}));

const SignUpContent = styled('div')(() => ({
  display: 'flex',
  marginTop: '1rem',
  justifyContent: 'flex-end'
}));

const LetsGetStartedContent = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing('2rem', '2rem', 0, '2rem'),
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing('5rem', '15rem', 0, '15rem')
  }
}));

const ImgContent = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'unset'
  //maskImage:
}));

const LoginFormContainer = styled('div')(({ theme }) => ({
  margin: '2rem',
  [theme.breakpoints.up('md')]: {
    margin: '2rem 15rem'
  }
}));

const StyledTypography = styled(Typography)(() => ({
  color: '#385B95'
}));

const HelloStyledTypography = styled(StyledTypography)(() => ({
  fontSize: '1.875rem', // 1 rem = 16px
  lineHeight: '1.36' // 1 LH = 30px
}));

export default function Login() {
  return (
    <RootStyle title="Login">
      <LoginPageContent>
        <ImgContent src="/static/illustrations/illustration_login.svg" alt="login" />
        <span></span>
      </LoginPageContent>
      <LoginPageContent>
        <SignUpContainer>
          <SignUpContent>
            <StyledTypography variant="body2">
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </StyledTypography>
          </SignUpContent>

          <LetsGetStartedContent>
            <HelloStyledTypography variant="body2">Hello, Welcome back</HelloStyledTypography>
          </LetsGetStartedContent>

          <LoginFormContainer>
            <LoginForm />
          </LoginFormContainer>
        </SignUpContainer>
      </LoginPageContent>
    </RootStyle>
  );
}
